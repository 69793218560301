<template>
    <div>
        <van-card
            v-for="item in data"
            :key="item.id"
            :price="item.price"
            :title="item.title"
            :thumb="item.thumb_pic"
            :desc="item.share_title"
            @click="orderList(item)"
            :lazy-load="true"
        >   
            <template #origin-price>
                ¥{{ item.price_show }}
            </template>
            <template #bottom>
                <div class="bottom">
                    <span>
                        <van-icon name="friends-o" size="17"/>{{ item.buy_num }}人已抢
                    </span>
                    <van-button size="mini" color="#EC2627">立即抢购</van-button>
                </div>
            </template>
        </van-card>
    </div>
</template>

<script>
export default {
    name: "GoodsList",
    props: ["data"],
    methods:{
        orderList(item){
            // MtaH5.clickStat("orderchildnum",{
            //     orderid:item.id
            // })
            window._czc.push(['_trackEvent','点击商品', '商品id','',item.id])
            console.log(window._czc)
            this.$emit('click', item)
        }
    }
};
</script>

<style lang="less" scoped>
.van-card {
    background: none;
    padding: 10px;
    margin: 0;

    .van-card__thumb {
        width: 137px;
        height: 139px;

        /deep/ img {
            border-radius: 5px;
            object-fit: fill !important;
            box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
        }
    }
    .van-card__title {
        color: #232323;
        font-size: 15px;
    }
    .van-card__price {
        color: #EC2627;
        font-weight: bold;
        font-size: 10px;
    }
    .van-card__price-currency {
        font-size: 9px;
    }
    .van-card__price-integer {
        font-size: 17px;
    }
    .van-card__desc {
        color: #666;
        font-size: 13px;
        margin-top: 2px;
    }
    .van-card__origin-price {
        font-size: 12px;
        color: #ccc;
    }
    .van-card__origin-price {
        float: right;
    }
    .van-card__bottom .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bottom .van-button {
        font-size: 12px;
    }
    .bottom > span {
        color: #999;
        font-size: 12px;
        .van-icon {
            vertical-align: -3px;
        }
    }
}
</style>
