<template>
  <van-tabbar v-model="active2" active-color="#07c160" inactive-color="#000" safe-area-inset-bottom style="z-index:999">
    <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
    <van-tabbar-item icon="gift-card-o" to="/recom">精品推荐</van-tabbar-item>
    <van-tabbar-item icon="gold-coin-o" to="/items">特价项目</van-tabbar-item>
    <van-tabbar-item icon="contact" to="/mycenter">个人中心</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
    props:{
        active:{
            type:Number,
        }
    },
    data: () => ({
      active2: "",
    }),
    created() {
      this.active2 = this.active;
    }
};
</script>